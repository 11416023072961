import React from "react"
import Block from "../components/block"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "@mui/material"
import SubHeader from "../components/subHeader"
import ScrollAnimation from "react-animate-on-scroll"
import ContactFormSection from "../components/contactFormSection"
import InstagramSection from "../components/instagramSection"
import OffersSlider from "../components/offersSlider"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import CallToAction from "../components/callToAction"
// image placeholder
import image from "../images/trial.jpg"

const Experience = ({ data }) => {
  const node = data.allWpExperience.edges[0].node
  const cto = node.experiences.about
  const slides = node.experiences.slider

  //   call to action
  const ctoHeader = cto.header
  const ctoContent = cto.content
  const ctoButton = cto.button.text
  const ctoButtonLink = cto.button.link
  const ctoImage = getSrc(cto.image?.localFile)

  return (
    <Layout>
      <Seo title={node.headerPost.pageTitle} />
      <SubHeader
        title={node.headerPost.pageTitle}
        image={getSrc(node.headerPost.backgroundImage.localFile)}
      />
      {node.trainingCourses.block.map((item, index) => {
        return (
          <Block
            key={index}
            title={item.headerb}
            content={item.content}
            button1={item.button?.text}
            button1_link={item.button?.link}
            button2={item.buttonSecondary?.text}
            button2_link={item.buttonSecondary?.link}
            img={getSrc(item.image?.localFile)}
            imgAlt={item.image?.altText}
            inverted={item?.inverted}
          />
        )
      })}
      <section
        className="flights"
        // style={!ctoHeader && !ctoContent ? { marginBottom: 0 } : {}}
      >
        <Container>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <h2>Choose a flight that suits you:</h2>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            delay={100}
          >
            <OffersSlider slides={slides} />
          </ScrollAnimation>
        </Container>
      </section>
      {ctoHeader && ctoContent && (
        <CallToAction
          header={ctoHeader}
          content={ctoContent}
          button={ctoButton}
          button_link={ctoButtonLink}
          img={ctoImage || image}
        />
      )}
      <ContactFormSection />
      <InstagramSection />
    </Layout>
  )
}

export default Experience

export const query = graphql`
  query ($id: String!) {
    allWpExperience(filter: { id: { eq: $id } }) {
      edges {
        node {
          headerPost {
            pageTitle
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          trainingCourses {
            block {
              headerb
              content
              button {
                link
                text
              }
              buttonSecondary {
                link
                text
              }
              inverted
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
                altText
              }
            }
          }
          experiences {
            slider {
              ... on WpExperience {
                title
                slug
                nodeType
                headerPost {
                  thumbnail {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
              ... on WpCourse {
                title
                slug
                nodeType
                headerPost {
                  thumbnail {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
            }
            about {
              header
              content
              button {
                text
                link
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
