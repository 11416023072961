import React from "react"
import Next from "../images/svgs/next.svg"
import { Link } from "gatsby"

const Offer = ({ image, header, link }) => {
  return (
    <Link
      to={link}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="offer__grid__box">
        <div className="gradient"></div>
        <h2>{header}</h2>

        <div className="viewDetails">
          <span className="btnText">View Details</span>
          <Next />
        </div>
      </div>
    </Link>
  )
}

export default Offer
