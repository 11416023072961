import React from "react"
import Offer from "./offer"
import Slider from "react-slick"
import Next from "../images/svgs/next.svg"
import Pre from "../images/svgs/pre.svg"
import { getSrc } from "gatsby-plugin-image"

const Offers = ({ slides }) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return <Next className={className} style={{ ...style }} onClick={onClick} />
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return <Pre className={className} style={{ ...style }} onClick={onClick} />
  }

  // slider settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Slider {...settings} className="offerSlider">
      {slides.map((slide, index) => {
        return (
          <div key={index} className="offer__grid">
            <Offer
              image={getSrc(slide?.headerPost?.thumbnail?.localFile)}
              header={slide.title}
              link={`/${slide.nodeType.toLowerCase()}s/${slide.slug}`}
            />
          </div>
        )
      })}
    </Slider>
  )
}

export default Offers
